/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Vocabulary } from "../../Utils/Vocabulary";
import useInterval from "../../Hooks/useInterval";
import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import useQueryStringParser from "../../Hooks/useQueryStringParser";
import styles from "../../Styles/chat.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import Config from "../../Utils/Config";
import { getData } from "../../Services/getData";
import { CommentsDisabled, Create } from "@mui/icons-material";
import SearchComponent from "../SearchComponent";
import GenericModal from "../GenericModal";
import StandardActionButtons from "../StandarsActionButtons";
import { FormsIds } from "../../Utils/Constants";
import { endpoints } from "../../Utils/UrlEnum";
import CustomInfiniteScrollList from "../CustomInfiniteScrollList";
import {
  checkIfChatHasUnreadMessages,
  formatTimestamp,
  stringAvatar,
} from "../../Utils/Utils";
import { EWidths } from "../../Utils/Enums";
import { postData } from "../../Services/postData";
import { updateData } from "../../Services/updateData";
import ClientsAutocomplete from "./ClientsAutocomplete";
import moment from "moment";

export default function MessengerList() {
  const [chats, setChats] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const conversationId = searchParams.get("conversation");

  /**
   *
   */
  useQueryStringParser((data: any) => {
    if (data) getChatsForUser(data.convSearch ? data.convSearch : "");
  });

  /**
   *
   */
  useInterval(() => {
    const search: any = searchParams.get("convSearch")
      ? searchParams.get("convSearch")
      : "";
    getChatsForUser(search);
  }, Config.poolingNewChatsInterval);

  /**
   *
   * @param search
   */
  const getChatsForUser = (search?: string) => {
    getData(
      `${endpoints.conversation.main}?${
        search && search?.length !== 0 ? `&search=${search}` : ""
      }`
    ).then((res: any) => {
      if (res && res.data) setChats(res.data);
    });
  };

  /**
   *
   */
  const toggleModalState = () => {
    setOpen(!open);
  };

  /**
   *
   * @param data
   */
  const handleSaveChat = (data: any) => {
    postData(endpoints.conversation.main, data).then((res: any) => {
      if (res && res.data) {
        //navigate to the new chat
        selectChat(res.data._id);
        //close modal
        toggleModalState();
        //update chat list
        getChatsForUser();
      }
    });
  };

  /**
   *
   * @param id
   */
  const selectChat = (id: string) => {
    //get chat based on id
    const chat = chats.find((chat) => chat._id === id);
    markConversationAdRead(chat);
    searchParams.set("conversation", id);
    setSearchParams(searchParams);
    // navigate(`${localUrlEnum.messages}/${id}`);
  };

  /**
   *
   * @param chat
   */
  const markConversationAdRead = (chat: any) => {
    if (chat?._id)
      updateData(`${endpoints.conversation.markConversationAsRead}`, chat).then(
        (res: any) => {
          if (res && res?.data) {
            getChatsForUser();
          }
        }
      );
  };

  /**
   *
   * @param chat
   * @returns
   */
  const getLastMessageDateFromTimestamp = (chat: any) => {
    if (chat?.messages?.length > 0) {
      const lastMessage = chat.messages[chat.messages.length - 1];
      return formatTimestamp(lastMessage.timestamp, Config.datePickerFormat);
    }
    return "";
  };

  return (
    <Paper className={styles.chatListContainer}>
      <div className={styles.chatListHeader}>
        <div className={styles.chatListHeaderTitle}>
          <Typography variant="h6">{Vocabulary.conversations}</Typography>
          <Tooltip title={Vocabulary.addNewConversation}>
            <IconButton
              size="small"
              className={styles.addNewChanelButton}
              onClick={toggleModalState}
            >
              <Create />
            </IconButton>
          </Tooltip>
        </div>
        <div
          className={`${genericStyles.searchField} ${styles.searchForChats}`}
        >
          <SearchComponent searchParamName="convSearch" />
        </div>
      </div>
      {chats?.length <= 0 ? (
        <div className={genericStyles.missingData}>
          <Typography variant="h6">
            {Vocabulary.noConversationsAvailable}
          </Typography>
          <CommentsDisabled fontSize="large" color="secondary" />
        </div>
      ) : (
        <CustomInfiniteScrollList
          id={chats?.length}
          listStyle={styles.chatList}
          items={chats}
          style={styles.customInfiniteScrollList}
          listItemsStyle={styles.messagesNotRead}
          render={(chatObject, index) => {
            return (
              <div
                className={`${styles.listItem}
                ${
                  conversationId === chatObject._id
                    ? styles.listItemNotRead
                    : ""
                }`}
                key={index}
                onClick={() => selectChat(chatObject._id)}
              >
                <ListItemAvatar>
                  <Avatar
                    {...stringAvatar(chatObject?.client?.name)}
                    sx={{
                      bgcolor:
                        conversationId === chatObject._id
                          ? "primary.dark"
                          : "primary.light",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        maxWidth: "fit-content",
                        wordBreak: "break-all",
                        fontWeight: checkIfChatHasUnreadMessages(chatObject)
                          ? "bold"
                          : "normal",
                      }}
                    >
                      {chatObject.client?.name?.toUpperCase() ||
                        chatObject?.potentialClientName?.toUpperCase() ||
                        ""}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption">
                      {chatObject.client?.phone?.[0] || chatObject?.wa_id}
                      <br />
                      {getLastMessageDateFromTimestamp(chatObject)}
                    </Typography>
                  }
                />
              </div>
            );
          }}
        />
      )}
      <GenericModal
        title={Vocabulary.newConversation}
        children={
          <ClientsAutocomplete
            formId={FormsIds.chatListForm}
            submitAction={handleSaveChat}
          />
        }
        maxWidth={EWidths.LG}
        actions={
          <StandardActionButtons
            cancelAction={toggleModalState}
            okText={Vocabulary.save}
            cancelText={Vocabulary.cancel}
            formId={FormsIds.chatListForm}
            type="submit"
          />
        }
        open={open}
        onClose={toggleModalState}
      />
    </Paper>
  );
}

export const clientsList: any = (defaultTheme: any) =>
  createTheme({
    ...defaultTheme,
    overrides: {
      MuiAutocomplete: {
        styleOverrides: {
          popper: {
            zIndex: 1300,
          },
        },
      },
    },
  });
